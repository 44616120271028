import React, { useState } from 'react'
// images
import logo from '../../../assets/img/logo.png';
// services
import AdvertisementService from '../../../services/advertisement';
// functions
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
// Components
import Loader from '../../components/Spinner/Loader';
import DynamicTitle from '../../../utils/DynamicTitle';
import MainInput from '../../components/general/MainInput';
// utils
import { useParams } from 'react-router-dom';

const AdvertisementsViewer = () => {
    const { fileName } = useParams();
    const [openLoader, setOpenLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(null);

    const [documento, setDocumento] = useState(null);

    /**/
    const containerStyles = {
        position: 'relative',
        width: '50%',
        maxWidth: '900px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        margin: '0 auto',
        marginTop: '25px',
        border: 'solid 1px #FF9CA6',
    };

    const formStyles = {
        // position: 'absolute',
        margin: '20px 20px 10px 20px',
        zIndex: 9999,
    };

    const downloadAdvertisement = async (e) => {
        e.preventDefault();
        try {
            if (!documento || documento?.trim() === "") {
                Toast.fire({
                    icon: 'error',
                    title: 'Debe colocar un documento.'
                });
                return;
            }
            setOpenLoader(true);
            setMessageLoader('Buscando archivo ...');
            const body = {
                documento,
                advertisement: fileName
            }
            const result = await AdvertisementService.downloadAdvertisement(body);
            if (result.status === CODES.SUCCESS_200) {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Error al descargar el archivo o documento no disponible.'
                });
            }
        } catch (error) {
            console.log(`Error al descargar el archivo: ${error}`);
        } finally {
            setOpenLoader(false);
            setMessageLoader(null);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            downloadAdvertisement(event);
        }
    };

    return (
        <div style={containerStyles}>
            <DynamicTitle title="Comparación PLAME" />
            <div style={formStyles}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.2rem', }}>
                    <img src={logo} alt="Logo" width={150} />
                </div>
                <form onSubmit={downloadAdvertisement} >
                    <div className='row d-flex justify-content-center'>
                        <div className="col-md-6 mt-3">
                            <MainInput
                                title={'Documento'}
                                value={documento}
                                name={'documento'}
                                type={'text'}
                                onChange={(e) => setDocumento(e.target.value)}
                                handleKeyPress={handleKeyPress}
                                required={true}
                            />
                        </div>
                        <div className="col-md-12 mt-4 mb-2 d-flex justify-content-center">
                            <button type="button" className="btn btn-primary" onClick={downloadAdvertisement}>Buscar Documento</button>
                        </div>
                    </div>
                </form>
            </div>
            <Loader
                open={openLoader}
                message={messageLoader}
            />
        </div>
    )
}

export default AdvertisementsViewer