import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class AdvertisementService {
    async downloadAdvertisement(body) {
        const ruta = `${API_URL}/LP/advertisements/download`;
        return axios.post(ruta, body, {
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }
}

export default new AdvertisementService();
